import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { StepUrl } from '../utils/enums';
import SEOLayout from '../components/layouts/SEOLayout';

const Index = () => {
  useEffect(() => {
    navigate(StepUrl.URL_POSTCODE_HOUSEHOLD, { replace: true });
  }, []);

  return <SEOLayout />;
};

export default Index;
